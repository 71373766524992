import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
	position: {
		marginTop: '-22em',
		minHeight: '35vh',
		[theme.breakpoints.down('lg')]: {
			marginTop: '-16em',
			minHeight: '20vh',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-15em',
			minHeight: '22vh',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '-9em',
			minHeight: '26vh',
		},
	},
	root: {
		color: theme.workwaveBlue,
		height: '150px',
		padding: '0px',
		borderRadius: '6px',
		position: 'relative',
		transition: 'transform 0.25s',
		minWidth: '460px',
		[theme.breakpoints.down('xs')]: {
			minWidth: '240px',
		},
	},
	text: {
		color: theme.workwaveBlue,
		fontSize: '1.2rem',
	},
	phoneNum: {
		color: theme.workwaveBlue,
		fontWeight: 'bold',
		fontSize: '1.4rem',
		textDecoration: 'none',
	},
	card: {
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
}));

export const SupportCard = ({ support, content }) => {
	const classes = useStyles();

	const lg = useMediaQuery('(max-width: 1280px)');
	const md = useMediaQuery('(max-width: 960px)');
	return (
		<Grid
			container
			direction='column'
			alignItems='center'
			justifyContent='center'
			className={classes.position}>
			{/* <Grid item xs={4}> */}
			<Card className={classes.root} elevation={3}>
				<CardContent
					style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}>
					<Grid item xs={12} lg={11} style={{ textAlign: 'center' }}>
						<Grid
							container
							item
							direction='row'
							justifyContent='center'
							alignItems='center'>
							<FontAwesomeIcon
								icon={['fad', support.heroCardfaIcon]}
								style={{
									color: '#002D5C',
									height: '55px',
									width: '55px',
									border: `1px solid #E5EAF4`,
									borderRadius: '8px',
									background: '#E5EAF4',
									padding: '6px',
									textAlign: 'center',
								}}
							/>
						</Grid>
						{/* This is a band aid for pre localization. will need to change to a new field in sanity eventually. */}
						<a
							href={
								`tel:1` +
								support.heroCardPhoneNumber
									.replace(' ', '')
									.replace('-', '')
									.replace('(', '')
									.replace(')', '')
							}
							className={classes.phoneNum}>
							+1 {support.heroCardPhoneNumber}
						</a>
						<Typography variant='body1' className={classes.text}>
							{support.heroCardBody}
						</Typography>
					</Grid>
				</CardContent>
			</Card>

			{/* </Grid> */}
		</Grid>
	);
};
