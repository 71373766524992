import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';
import { SupportCard } from '../components/Support/SupportCard';
import { SupportExternalContactForm } from '../components/Support/SupportExternalContactForm';
import FormModalContext from '../context/FormModalContext';

// import { Form } from '../components/ContactForm/Form';

const Form = loadable(() => import('../components/ContactForm/Form'));

const SupportPage = ({ data, location }) => {
	const support = data.support.edges[0].node;
	const {
		hero,
		supportHeroContactCard,
		metaDescription,
		metaTitle,
		contactForm,
		externalForm,
	} = support;

	const { formModalOpen } = useContext(FormModalContext);

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />

			<Hero hero={hero} />
			<SupportCard support={supportHeroContactCard} />
			<SupportExternalContactForm
				formValues={externalForm}
				contactForm={contactForm}
				endPoint='https://workwave.my.salesforce-sites.com/web2case/services/apexrest/cases/create'
				//endPoint='https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8'
			/>
			{formModalOpen ? (
				<Form
					privacy
					noForm
					location={location}
					pardotUrl='https://go.workwave.com/l/1042281/2023-11-16/65swnn'
				/>
			) : null}
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query SupportQuery {
		support: allSanitySupport {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData
							}
						}
						_rawContent
					}
					supportHeroContactCard {
						heroCardBody
						heroCardPhoneNumber
						heroCardfaIcon
					}
					contactForm {
						header
						privacyPolicy
					}
					externalForm {
						fieldLabel
						fieldValue
						textArea
						externalFormSelect {
							fieldLabel
							fieldValue
						}
					}
				}
			}
		}
	}
`;

export default SupportPage;
